import React from 'react'
import { Grid } from '@chakra-ui/react'
import Body from 'components/Blocks/Body'

const RichText = ({ value }) => {
  return <Grid pt={'10rem'} mb={'5rem'} px={0} gap={'2rem'} gridRowGap={0} templateColumns={['repeat(8, 1fr)', null]} sx={{
    '> div + div': {
      mt: 100
    },
    '> p + p': {
      mt: 2
    },
    '> div + h2': {
      mt: 100
    },
    '> h2 + p': {
      mt: 50
    },
    '> p + h2': {
      mt: 100
    },
    '> div + p, > p + div': {
      mt: 100
    },
    '> #toc + h2': {
      mt: 0
    }
  }}>
    <Body value={value.body} />
  </Grid>
}

export default RichText
