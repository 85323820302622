import React from 'react'
import { Flex, Grid, GridItem, Heading, Text, LinkBox } from '@chakra-ui/react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { PortableText } from '@portabletext/react'
import { motion } from 'framer-motion'
import { getGatsbyImageData } from 'utils/getGatsbyImageProps'
import { LinksArray } from 'components/Modules'

const GridModule = ({ value = {} }) => {
  return value.items && <Grid gap={'5rem'} px={'5rem'} mb='15rem' templateColumns={['repeat(4, 1fr)', null]}>
    {value.items.map(item =>
      <GridItem key={item._key} gridColumn={'span 2'} pb={'1rem'} as={motion.div} initial={'rest'} whileHover={'hover'} animate={'rest'}>
        <Flex as={LinkBox} direction='column'>
          <GatsbyImage mb={'1.5rem'} image={getGatsbyImageData(item.image, { width: 800, height: 600, hotspot: item.image?.hotspot, crop: item.image?.crop, placeholder: 'dominantColor' })} alt={item.image?.asset?.altText || '' } />
          {item.title && <Heading size='lg' mt={'1.5rem'} mb={'1.5rem'}>{item.title}</Heading>}
          <PortableText
            value={item.body}
            components={{
              block: {
                normal: ({children}) => <Text my={'1.5rem'} textStyle={{ base: 'rism', lg: 'rimd' }}>{children}</Text>
              },
            }}
          />
          { item.links && <LinksArray value={item.links} mt={'1.5rem'} />}
        </Flex>
      </GridItem>
    )
  }</Grid>
}

export default GridModule
