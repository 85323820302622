import React from 'react'
import { Text, LinkBox } from '@chakra-ui/react'
import { LinksArray } from 'components/Modules'
import { motion } from 'framer-motion'

const Callout = ({ value }) => {
  const { text, links } = value
  return <LinkBox
    as={motion.div}
    initial={'rest'}
    whileHover={'hover'}
    animate={'rest'}
  >
    <Text size='2xl' lineHeight='shorter' mb={'5rem'}>{text}</Text>
    <LinksArray value={links} />
  </LinkBox>
}

export default Callout