import React from 'react'
import { GridItem, Text, Heading, LinkBox, chakra } from '@chakra-ui/react'
import { motion, isValidMotionProp } from 'framer-motion'
import Body from 'components/Blocks/Body'
import { LinksArray } from 'components/Modules'

// @TODO: consider using `colorTheme` here with a fallback to `white`

const ChakraDiv = chakra(motion.div, {
  shouldForwardProp: (prop) => isValidMotionProp(prop) || prop === 'children',
})

const paragraph = {
  hidden: {
    opacity: 0,
    y: '2rem'
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      y: {
        delay: .25,
        duration: 0.4
      },
      opacity: {
        delay: 0.4,
        duration: 0.4
      }
    }
  }
}

const CallToAction = ({ value }) => {
  const { title, body, content, links } = value
  return <React.Fragment>
    <GridItem gridColumn={{ base: '1/9', lg: '1/9' }} mb={'2rem'} mt={'10rem'}>
      <Heading as='h1' size='2xl' variant='caps' lineHeight='shorter' mb={'2rem'}><Text as={'span'} variant={'case'}>→ </Text>{title}</Heading>
      <Body value={content} />
    </GridItem>
    <GridItem gridColumn={{ base: '1/9', lg: '1/5' }}>
      <ChakraDiv
        variants={paragraph}
        initial={'hidden'}
        whileInView={'show'}
        viewport={{ once: true }}
      ><Text size='md'>{body}</Text></ChakraDiv>
    </GridItem>
    <LinkBox as={motion.div} initial={'rest'} whileHover={'hover'} animate={'rest'} gridColumn={{ base: '1/9', lg: '5/9' }} alignSelf={'end'} textAlign={'right'}>
      <LinksArray value={links} />
    </LinkBox>
  </React.Fragment>
}

export default CallToAction
