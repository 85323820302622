import React, { useRef, useEffect, useState } from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { LinkOverlay, Text, chakra } from '@chakra-ui/react'
import { motion, isValidMotionProp } from 'framer-motion'

const ChakraSpan = chakra(motion.span, {
  shouldForwardProp: (prop) => isValidMotionProp(prop) || prop === 'children',
})

const arrow = {
  rest: {
    opacity: 0,
    x: '-1rem'
  },
  hover: {
    opacity: 1,
    x: 0,
    transition: {
      x: {
        delay: .25,
        duration: 0.4
      },
      opacity: {
        delay: 0.4,
        duration: 0.4
      }
    }
  }
}

// eslint-disable-next-line react/display-name
const LinkArrow = ({ entity = '→', arrowRef }) =>
  <ChakraSpan
    ref={arrowRef}
    position={'absolute'}
    right={0}
    variants={arrow}
    //initial={'rest'}
    //whileInView={'hover'}
    //viewport={{ once: true }}
  >&nbsp;{entity}</ChakraSpan>

const LinksArray = ({ value, textStyle }) => {
  const arrowRef = useRef()
  const [arrowOffset, setArrowOffset] = useState(0)

  useEffect(() => {
    if (arrowRef.current) {
      setArrowOffset(arrowRef.current?.getBoundingClientRect().width)
    }
  })

  const link = value && value[0] || {}
  let path
  if (link.reference?._type === 'article.editorial') {
    path = `/article/${link.reference?.slug?.current}`
  } else if (link.reference?._type === 'product') {
    path = `/product/${link.reference?.slug?.current}`
  } else {
    path = link.reference?.slug?.current
  }

  if (!link) return <div/>
  return link._type === 'linkInternal' ?
    <LinkOverlay
      as={GatsbyLink}
      textStyle={textStyle || 'rimd'}
      to={path}
      sx={{
        '.text': {
          transition: 'text-decoration-color 300ms, text-underline-offset 300ms',
          textDecorationLine: 'underline',
          textUnderlineOffset: '0.25em',
          textDecorationColor: 'black',
          textDecorationThickness: '0.075em'
        }
      }}
      _hover={{
        '.text': {
          textUnderlineOffset: '0.5em',
          textDecorationColor: 'transparent',
        }
      }}
    >
    <Text as='span' position={'relative'} pr={`${arrowOffset}px`}><span className='text'>{link.title}</span><LinkArrow arrowRef={arrowRef} /></Text>
    </LinkOverlay> :
    <LinkOverlay href={link.url} target={link.newWindow  && '_blank'}>{link.title}<Text as={'span'} variant={'case'}> ↗</Text></LinkOverlay>
}

export default LinksArray
