import React from 'react'
import { graphql } from 'gatsby'
import { Grid, GridItem, Heading, Text } from '@chakra-ui/react'
import Transition from 'components/layout/Transition'
import Modules from 'components/Modules'
import { HeroPage } from 'components/Hero'

const Page = ({ data: { page } }) => {
  const { showHero, title, _rawHero } = page || {}
  return (
    <Transition>
      { showHero && _rawHero ? <HeroPage value={_rawHero} /> :
        <Grid as='header'><GridItem gridColumn={{ base: '3/9', xl: '4/8' }} gridRow={'4/7'}><Heading as='h1' size='2xl' variant='caps' lineHeight='shorter'><Text as={'span'} variant={'case'}>→ </Text>{title}</Heading></GridItem></Grid>
      }
      <Grid mb={'5rem'} px={'5rem'} gap={'2rem'} gridRowGap={0} templateColumns={['repeat(8, 1fr)', null]}>
        <Modules value={page?._rawModules} />
      </Grid>
    </Transition>
  )
}

export default Page

export const query = graphql`
query($id: String!)
{
  page: sanityPageInfo(id: {eq: $id}) {
    _id
    title
    showHero
    _rawHero(resolveReferences: {maxDepth: 10})
    _rawModules(resolveReferences: {maxDepth: 10})
  }
}`
