import React from 'react'
import { Heading, Text, chakra } from '@chakra-ui/react'
import { motion, isValidMotionProp } from 'framer-motion'

const ChakraSpan = chakra(motion.span, {
  shouldForwardProp: (prop) => isValidMotionProp(prop) || prop === 'children',
})

const arrow = {
  hidden: {
    opacity: 0,
    x: '-2rem'
  },
  show: {
    opacity: 1,
    x: 0,
    transition: {
      x: {
        delay: .5,
        duration: 0.5
      },
      opacity: {
        delay: 0.75,
        duration: 0.5
      }
    }
  }
}

// @TODO: Consider animating the arrow in whileInView
// the entire heading would need to animate...
// if the line breaks, then we should only animate the first line
// is there an elegant and simple way to check if the line is breaking?

const HeadingArrow = ({ entity = '→' }) =>
  <ChakraSpan
    position={'absolute'}
    left={0}
    variants={arrow}
    initial={'hidden'}
    whileInView={'show'}
    viewport={{ once: true }}
  >{entity}</ChakraSpan>

const SectionHeading = ({ value }) => {
  return <Heading as='h1' size='2xl' variant='caps' lineHeight='shorter' mb={'2rem'}><Text as={'span'} variant={'case'}>→ </Text>{value.title}</Heading>
}

export default SectionHeading
