import React from 'react'
import { PortableText } from '@portabletext/react'
import clientConfig from '../../../client-config'
import { Box, Text, GridItem as Gi } from '@chakra-ui/react'
import BlockCarousel from 'components/Blocks/Carousel'
import BlockProductCarousel from 'components/Blocks/ProductCarousel'
import Video from 'components/Modules/Video'
import BlockImage from 'components/Blocks/Image'
import BlockProduct from 'components/Blocks/Product'
import CallToAction from 'components/Modules/CallToAction'
import Callout from 'components/Modules/Callout'
import RichText from 'components/Modules/RichText'
import SectionHeading from 'components/Modules/Heading'
import Gallery from 'components/Modules/Gallery'
import Grid from 'components/Modules/Grid'
import LinksArray from 'components/Modules/LinksArray'

const InlineProductMarginalia = ({ value }) => {
  return <span id={value._key}></span>
}

export {
  LinksArray,
  Grid,
  Gallery,
  SectionHeading,
  RichText,
  Callout,
  CallToAction,
  BlockProduct,
  BlockImage,
  Video,
  BlockCarousel,
  BlockProductCarousel
}

export const GRID_COLUMN = { base: '1/9', lg: '1/7', xl: '3/7' }

const components = {
  types: {
    'module.grid': (props) =>
      <Gi gridColumn={'1/9'} mt={'10rem'}><Grid {...props} /></Gi>,
    'module.gallery': (props) =>
      <Gi gridColumn={'1/9'} mt={'10rem'}><Gallery {...props} /></Gi>,
    'module.heading': (props) =>
      <Gi gridColumn={'1/9'} mt={'10rem'}><SectionHeading {...props} /></Gi>,
    'module.richText': (props) =>
      <Gi gridColumn={'1/9'}><RichText {...props} /></Gi>,
    'module.callout': (props) =>
      <Gi gridColumn={'1/9'} mt={'200px'} mb={'10rem'}><Callout {...props} /></Gi>,
    'module.callToAction': (props) =>
      <CallToAction {...props} />,
    'module.product': (props) =>
      <Gi gridColumn={'1/9'}><BlockProduct smallText {...props} /></Gi>,
    'module.image': (props) =>
      <Gi gridColumn={props.value.fullWidth ? { base: '1/9', xl: '3/7' } : '1/6'} mx={{ base: '-50px', xl: 0 }} mt={'5rem'}><BlockImage {...props} fullWidth={props.value.fullWidth} value={props.value} /></Gi>,
    'module.video': (props) =>
      <Gi mx={props.value.isBackground && '-50px'} gridColumn={props.value.isBackground ? '1/9' : GRID_COLUMN}><Video {...props} /></Gi>,
    'module.carousel': (props) => props.value?.variant === 'images' ?
      <BlockCarousel {...props} /> : <BlockProductCarousel {...props} />,
    blockInlineProductMarginalia: InlineProductMarginalia,
  }
}

const Modules = ({ value }) => {
  return <PortableText
    value={value}
    components={components}
    onMissingComponent={false}
    {...clientConfig.sanity}
  />
};

export default Modules
